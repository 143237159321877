import { Route } from '@angular/router';
import { DomainGuard } from './guards/domain.guard';
import { CommonGuard } from './guards/common.guard';
import { PrivacyPolicyComponent } from '@app-auth/feature/privacy-policy/privacy-policy.component';

export const APP_ROUTES: Route[] = [
  {
    path: '',
    redirectTo: 'common',
    pathMatch: 'full'
  },
  {
    canActivate: [CommonGuard],
    path: 'login',
    loadChildren: () => import('./common/feature-shell/login.routes').then((r) => r.LoginRoutes)
  },
  {
    path: 'politica-privacidade',
    loadChildren: () => import('./common/feature-shell/privacy-policy.routes').then((r) => r.PrivacyPolicyRoutes)
  },
  {
    canActivate: [CommonGuard],
    path: 'common',
    loadChildren: () => import('./common/feature-shell/common.routes').then((r) => r.CommonRoutes)
  },
  {
    canActivate: [DomainGuard],
    path: '',
    loadChildren: () => import('./core/core.routes').then((r) => r.CoreRoutes)
  },
];
