import { Injectable, inject } from "@angular/core";
import { AboutGymStore } from '@app-about-gym/data-access/about-gym.store';
import { ActivityStore } from '@app-activities/data-access/activities.store';
import { ChatStore } from '@app-chat/data-access/chat.store';
import { PaymentsStore } from '@app-payments/data-access/payments.store';
import { FeedStore } from '@app-feed/data-access/post.store';
import { FrequencyStore } from '@app-frequency/data-access/frequency.store';
import { GraduationStore } from "@app-graduation/data-access/graduation.store";
import { PresentationStore } from '@app-presentation/data-access/presentation.store';
import { ProfileStore } from '@app-profile/data-access/profile.store';
import { QrCodeStore } from '@app-qr-code/data-access/qr-code.store';
import { TimersStore } from '@app-timers/data-access/timers.store';
import { WorkoutStore } from '@app-training/data-access/workout.store';
import { AlertStore } from '@shared/components/alert/data-access/alert.store';
import { UserPhotoStore } from "@shared/components/user-photo/data-access/user-photo.store";
import { PushNotificationsStore } from '@shared/stores/push-notifications.store';
import { AuthStore } from "@app-auth/data-access/auth.store";
import { PhysicalAssessmentsFacade } from "@app-facades/physical-assessments/physical-assessments.facade";
import { LogoutStore } from "@app-logout/data-access/logout.store";

@Injectable({
    providedIn: 'root'
}) export class LogoutService {
    public readonly aboutGymStore = inject(AboutGymStore);
    public readonly activityStore = inject(ActivityStore);
    public readonly chatStore = inject(ChatStore);
    public readonly paymentsStore = inject(PaymentsStore);
    public readonly feedStore = inject(FeedStore);
    public readonly frequencyStore = inject(FrequencyStore);
    public readonly graduationStore = inject(GraduationStore);
    public readonly presentationStore = inject(PresentationStore);
    public readonly profileStore = inject(ProfileStore);
    public readonly qrCodeStore = inject(QrCodeStore);
    public readonly timersStore = inject(TimersStore);
    public readonly workoutStore = inject(WorkoutStore);
    public readonly alertStore = inject(AlertStore);
    public readonly userPhotoStore = inject(UserPhotoStore);
    public readonly pushNotificationsStore = inject(PushNotificationsStore);
    public readonly authStore = inject(AuthStore);
    public readonly logoutStore = inject(LogoutStore);
    public readonly physicalAssessmentsFacade = inject(PhysicalAssessmentsFacade);

    public resetAllStores(): void {
        this.authStore.clearData();
        this.aboutGymStore.resetStore();
        this.activityStore.resetStore();
        this.chatStore.resetStore();
        this.paymentsStore.resetStore();
        this.feedStore.resetStore();
        this.frequencyStore.resetStore();
        this.graduationStore.resetStore();
        this.presentationStore.resetStore();
        this.profileStore.resetStore();
        this.qrCodeStore.resetStore();
        this.timersStore.resetStore();
        this.workoutStore.resetStore();
        this.alertStore.resetStore();
        this.userPhotoStore.resetStore();
        this.pushNotificationsStore.resetStore();
        this.logoutStore.resetStore();
        this.physicalAssessmentsFacade.resetFacade();
    }

}