import { inject } from '@angular/core';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { ActivityService } from '@app-activities/data-access/infra/activities.service';
import { tapResponse } from "@ngrx/operators";
import { catchError, concatMap, iif, of, pipe, switchMap, tap } from 'rxjs';
import { AlertStore } from '@shared/components/alert/data-access/alert.store';
import { ErrorHandlerService } from '@app-services/error-handler.service';
import { HttpErrorResponse } from '@angular/common/http';
import { IActivitySettings, IGroup, IGroupDash, IRequestChangeStatus } from '@app-activities/data-access/entities/activities.interface';
import { ECardStatus, ETime } from '@app-activities/data-access/entities/activities.enum';
import { DateTime } from 'luxon';
import { AuthStore } from '@app-auth/data-access/auth.store';
import { setPropError, setPropInit, setPropLoaded, setPropLoading, withReqState } from '@shared/stores/prop-state.store';
import { PresentationStore } from '@app-presentation/data-access/presentation.store';
import { EAlertTypes } from '@shared/components/alert/data-access/entities/alert.enum';
import { HapticsStore } from '@shared/stores/haptics.store';
import { ImpactStyle } from '@capacitor/haptics';
import { ProfileStore } from '@app-profile/data-access/profile.store';
export interface IActivityState {
    ClassesPlanned: IGroupDash;
    ReserveList: IGroupDash;
    ParticipantStatusState: Record<string, 'loaded' | 'loading' | 'error'>;
    ActivitySettings: IActivitySettings | null;
};

const initialState: IActivityState = {
    ClassesPlanned: {
        groupsDash: [],
        isOrderDescending: false,
        taked: 0,
        isFinished: false,
    },
    ReserveList: {
        groupsDash: [],
        isOrderDescending: false,
        taked: 0,
        isFinished: false,
    },
    ParticipantStatusState: {},
    ActivitySettings: null,
};

export const ActivityStore = signalStore(
    { providedIn: 'root' },
    withState(initialState),
    withMethods((
        store,
        presentationStore = inject(PresentationStore),
        activityService = inject(ActivityService),
        authStore = inject(AuthStore),
        alertStore = inject(AlertStore),
        errorHandlerService = inject(ErrorHandlerService),
        hapticsStore = inject(HapticsStore),
        profileStore = inject(ProfileStore)
    ) => {

        const canCheckinWithoutReservation = (group: IGroup, newStatus: ECardStatus) => {
            const oldStatus = group.ParticipantStatus;
            const activitySettings = store.ActivitySettings();
            if (!activitySettings) return false;

            const { AllowCheckinWithoutReservation, TimeBeforeCheckinWithoutReservation, TimeTypeBeforeCheckinWithoutReservation } = activitySettings;

            if (newStatus === ECardStatus.Confirmed && oldStatus !== ECardStatus.Reserved &&
                AllowCheckinWithoutReservation && TimeBeforeCheckinWithoutReservation) {

                const occurredOn = DateTime.fromISO(String(group.OccurredOn));
                const now = DateTime.now();
                const timeTypeName = ETime[TimeTypeBeforeCheckinWithoutReservation] as 'minute' | 'hour';
                const diffNow = occurredOn.diff(now).as(timeTypeName);

                if (diffNow > TimeBeforeCheckinWithoutReservation) {
                    alertStore.openAlert({
                        title: 'Check-in indisponível!',
                        message: `Só é possível fazer o check-in ${TimeBeforeCheckinWithoutReservation} ${TimeTypeBeforeCheckinWithoutReservation === ETime.hour ? 'horas' : 'minutos'} antes do início da aula.`,
                        type: EAlertTypes.WARNING,
                        mode: 'alert',
                    });
                    return false;
                }
            }
            return true;
        }

        const canCancelSchedule = (OccurredOn: string) => {
            const activitySettings = store.ActivitySettings();
            if (!activitySettings) return false;

            const { TimeLimitGroupCancellation, TimeTypeLimitGroupCancellation } = activitySettings;

            if (TimeLimitGroupCancellation) {
                const occurredOn = DateTime.fromISO(OccurredOn);
                const timeTypeName = ETime[TimeTypeLimitGroupCancellation] as 'minute' | 'hour';
                const diffNow = occurredOn.diff(DateTime.now()).as(timeTypeName);

                if (TimeLimitGroupCancellation > diffNow) {
                    alertStore.openAlert({
                        title: 'Cancelamento indisponível!',
                        message: `Só é possível cancelar o agendamento com ${TimeLimitGroupCancellation} ${TimeTypeLimitGroupCancellation === ETime.hour ? 'horas' : 'minutos'} de antecedência.`,
                        type: EAlertTypes.WARNING,
                        mode: 'alert',
                    });
                    return false;
                }
            }
            return true;
        }

        const changeParticipantStatus = rxMethod<{ group: Partial<IGroup>, newStatus: ECardStatus }>(pipe(
            switchMap(({ group, newStatus }) => {
                const payload: IRequestChangeStatus = {
                    PersonId: authStore.getAuthData()?.PersonId,
                    GroupId: group.GroupId!,
                    OccurredOn: group.OccurredOn!,
                    Status: newStatus,
                    IsCheckinPerson: profileStore.UserData()?.TipoCadastro === 'V'
                }
                const request = group.ParticipantId ? activityService.updateParticipant({ ...payload, ParticipantId: group.ParticipantId }) : activityService.createParticipant(payload);
                return iif(
                    () => {
                        const canRequest = canCheckinWithoutReservation(group as IGroup, newStatus);
                        if (canRequest) patchState(store, { ParticipantStatusState: { ...store.ParticipantStatusState(), [group.OccurredOn! + group.GroupId!]: 'loading' }, ...setPropLoading('ChangeParticipantStatus') });
                        return canRequest;
                    },
                    request.pipe(
                        tapResponse({
                            next: ({ ParticipantId, ParticipantStatus, PersonId, PersonName }) => {
                                hapticsStore.triggerImpact(ImpactStyle.Light);
                                group.ParticipantStatus = ParticipantStatus;
                                if (!group.ParticipantId) {
                                    group.ParticipantId = ParticipantId;
                                    group.NumberVacanciesAvailable! -= 1;
                                    group.Participants?.push({ ParticipantId, ParticipantStatus, PersonId, Name: PersonName });
                                }

                                patchState(store, { ParticipantStatusState: { ...store.ParticipantStatusState(), [group.OccurredOn! + group.GroupId!]: 'loaded' }, ...setPropLoaded('ChangeParticipantStatus') });
                                alertStore.openAlert({
                                    title: ParticipantStatus === ECardStatus.Reserved ? 'Reserva realizada!' : 'Check-in realizado!',
                                    message: ParticipantStatus === ECardStatus.Reserved ? 'Lembre-se de fazer o check-in antes da aula começar.' : 'Seu check-in foi realizado com sucesso.',
                                    type: EAlertTypes.SUCCESS,
                                    mode: 'alert',
                                });
                            },
                            error: (error: HttpErrorResponse) => {
                                errorHandlerService.handleError(error);
                                patchState(store, { ParticipantStatusState: { ...store.ParticipantStatusState(), [group.OccurredOn! + group.GroupId!]: 'error' }, ...setPropError('ChangeParticipantStatus') });
                            }
                        }),
                    ),
                    of(null)
                )
            })
        ))

        const clearConfigClassesPlanned = () => {
            patchState(store, { ClassesPlanned: {} }, setPropInit('ClassesPlanned'));
        }

        const clearConfigReserveList = () => {
            patchState(store, { ReserveList: {} }, setPropInit('ReserveList'));
        }

        const setParticipantData = (groups: IGroup[], personId: string): IGroup[] => {
            return groups.map(group => {
                const participant = group.Participants?.find(participant => participant.PersonId === personId);
                return {
                    ...group,
                    ParticipantId: group.ParticipantId ?? participant?.ParticipantId ?? null,
                    ParticipantStatus: group.ParticipantStatus ?? participant?.ParticipantStatus ?? null,
                };
            });
        }

        const groupsAreEqual = (groupsDash: IGroup[], loadedGroups: IGroup[]): boolean => {
            if (groupsDash?.length !== loadedGroups?.length) return false;

            for (let i = 0; i < groupsDash?.length; i++) {
                if (!objectsAreEqual(groupsDash[i], loadedGroups[i])) {
                    return false;
                }
            }

            return true;
        }

        const objectsAreEqual = (obj1: any, obj2: any): boolean => {
            if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
                return obj1 === obj2;
            }

            const keys1 = Object.keys(obj1);
            const keys2 = Object.keys(obj2);

            if (keys1?.length !== keys2?.length) {
                return false;
            }

            for (const key of keys1) {
                if (!keys2?.includes(key)) {
                    return false;
                }
                if (!objectsAreEqual(obj1[key], obj2[key])) {
                    return false;
                }
            }

            return true;
        }

        const updateCurrentData = (
            loadedGroups: IGroup[],
            isOrderDescending: boolean,
            isFinished: boolean,
            groups: IGroupDash
        ): IGroupDash => {
            const shouldReplaceData = groups.groupsDash && (groupsAreEqual(groups.groupsDash, loadedGroups) || isOrderDescending !== groups.isOrderDescending);

            return {
                ...groups,
                groupsDash: shouldReplaceData ? loadedGroups : (groups.groupsDash?.concat(loadedGroups) ?? loadedGroups),
                taked: (groups.taked ?? 0) + 1,
                isFinished,
                isOrderDescending
            };
        }

        return {
            changeParticipantStatus,

            getActivitySettings: rxMethod<void>(pipe(
                tap(() => patchState(store, { ...setPropLoading('GetActivitySettings') })),
                switchMap(() => activityService.getActivitySettings().pipe(
                    tapResponse(
                        (response) => {
                            patchState(store, { ActivitySettings: response, ...setPropLoaded('GetActivitySettings') });
                        },
                        (error: HttpErrorResponse) => {
                            errorHandlerService.handleError(error);
                            patchState(store, { ...setPropError('GetActivitySettings', error) });
                        }
                    )
                ))
            )),

            getReserveList: rxMethod<Partial<IGroupDash>>(pipe(
                tap(({ isOrderDescending }) => patchState(store, { ReserveList: { ...store.ReserveList(), isOrderDescending }, ...setPropLoading('ReserveList') })),
                concatMap(({ isOrderDescending, scrolling, startOn, endOn }) => {
                    startOn = startOn ?? DateTime.now().startOf('day').toISO({ includeOffset: false });
                    endOn = endOn ?? DateTime.now().endOf('day').toISO({ includeOffset: false });
                    isOrderDescending = isOrderDescending ?? false;
                    return activityService.getParticipationsByPersonId({
                        personId: String(authStore.getAuthData()?.PersonId),
                        startOn,
                        endOn,
                        take: 10,
                        skip: scrolling ? (store.ReserveList().taked ?? 0) * 10 : 0,
                        isOrderDescending
                    }).pipe(
                        tap({
                            next: (response) => {
                                const personId = authStore.getAuthData()?.PersonId ?? '';
                                const reserveList = setParticipantData(response, personId);
                                const isFinished = response.length < 10;
                                const updatedReserveList = updateCurrentData(reserveList, isOrderDescending, isFinished, store.ReserveList());
                                patchState(store, { ReserveList: { ...updatedReserveList, startOn, endOn, isOrderDescending }, ...setPropLoaded('ReserveList') });
                            },
                            error: (error) => {
                                patchState(store, { ...setPropError('ReserveList', error) });
                                errorHandlerService.handleError(error);
                                presentationStore.reload(false);
                            }
                        })
                    )
                })
            )),

            getClassesPlanned: rxMethod<Partial<IGroupDash>>(pipe(
                tap(({ isOrderDescending }) => patchState(store, { ClassesPlanned: { ...store.ClassesPlanned(), isOrderDescending }, ...setPropLoading('ClassesPlanned') })),
                concatMap(({ startOn, endOn, isOrderDescending, scrolling }) => {
                    startOn = startOn ?? DateTime.now().startOf('day').toISO({ includeOffset: false });
                    endOn = endOn ?? DateTime.now().endOf('day').toISO({ includeOffset: false });
                    isOrderDescending = isOrderDescending ?? false;
                    return activityService.getClassesPlanned({
                        take: 10,
                        skip: scrolling ? (store.ClassesPlanned().taked ?? 0) * 10 : 0,
                        startOn,
                        endOn,
                        isOrderDescending
                    }).pipe(
                        tap({
                            next: (response) => {
                                const personId = authStore.getAuthData()?.PersonId ?? '';
                                const groupsDash = setParticipantData(response, personId);
                                const isFinished = response.length < 10;
                                const updatedGroups = updateCurrentData(groupsDash, isOrderDescending, isFinished, store.ClassesPlanned());

                                patchState(store, { ClassesPlanned: { ...updatedGroups, startOn, endOn, isOrderDescending }, ...setPropLoaded('ClassesPlanned') });
                            },
                            error: (error) => {
                                patchState(store, { ...setPropError('ClassesPlanned', error) });
                                errorHandlerService.handleError(error);
                                presentationStore.reload(false);
                            }
                        })
                    )
                })
            )),

            deleteParticipant: rxMethod<IGroup>(pipe(
                switchMap((group) =>
                    iif(
                        () => {
                            const canRequest = canCancelSchedule(group.OccurredOn);
                            if (canRequest) patchState(store, { ParticipantStatusState: { ...store.ParticipantStatusState(), [group.OccurredOn + group.GroupId]: 'loading' }, ...setPropLoading('DeleteParticipant') });
                            return canRequest;
                        },
                        activityService.deleteParticipant({ OccurredOn: group.OccurredOn, GroupId: group.GroupId, ParticipantId: group.ParticipantId! }).pipe(
                            tapResponse({
                                next: () => {
                                    hapticsStore.triggerImpact(ImpactStyle.Light);
                                    alertStore.openAlert({
                                        title: 'Reserva cancelada!',
                                        message: 'Sua reserva foi cancelada com sucesso.',
                                        type: EAlertTypes.SUCCESS,
                                        mode: 'alert',
                                    });

                                    const reserveListFiltered = store.ReserveList().groupsDash!.filter(g => g.ParticipantId !== group.ParticipantId) ?? [];
                                    group.ParticipantId = null;
                                    group.ParticipantStatus = null;
                                    group.Participants = group.Participants?.filter(participant => participant.PersonId !== authStore.getAuthData()?.PersonId);
                                    group.NumberVacanciesAvailable! += 1;

                                    patchState(store, { ParticipantStatusState: { ...store.ParticipantStatusState(), [group.OccurredOn + group.GroupId]: 'loaded' }, ...setPropLoaded('ChangeParticipantStatus') });
                                    patchState(store, { ReserveList: { ...store.ReserveList(), groupsDash: reserveListFiltered } });
                                },
                                error: (error: HttpErrorResponse) => {
                                    errorHandlerService.handleError(error);
                                    patchState(store, { ParticipantStatusState: { ...store.ParticipantStatusState(), [group.OccurredOn + group.GroupId]: 'error' }, ...setPropError('ChangeParticipantStatus') });
                                }
                            }),
                        ),
                        of(null)
                    )
                )
            )),

            checkInByGroupId: rxMethod<{ groupId: string, occuredOn: string }>(pipe(
                switchMap(({ groupId, occuredOn }) => activityService.getGroupFull(groupId, occuredOn).pipe(
                    tap((response) => {
                        const isCheckinPerson = profileStore.UserData()?.TipoCadastro === 'V';
                        const checkinPersonCannotReserve = !response?.GroupDash?.AvailableCheckinPerson;

                        if (isCheckinPerson && checkinPersonCannotReserve) {
                            alertStore.openAlert({
                                title: 'Ops!',
                                message: 'Aula não disponível para GymPass e TotalPass',
                                type: EAlertTypes.WARNING,
                                mode: 'alert',
                            });
                            return;
                        }

                        if (response.GroupDash.IsCanceled) {
                            alertStore.openAlert({
                                title: 'Aula cancelada!',
                                message: response.GroupDash.NoteGroup ?? 'Essa aula foi cancelada',
                                type: EAlertTypes.WARNING,
                                mode: 'alert',
                            });
                            patchState(store, { ParticipantStatusState: { ...store.ParticipantStatusState(), [occuredOn + groupId]: 'error' } });
                            return;
                        }

                        const participation = response.Participants.find(participant => participant.PersonId === authStore.getAuthData()?.PersonId);

                        if (participation?.ParticipantStatus === ECardStatus.Canceled) {
                            alertStore.openAlert({
                                title: 'Check-in indisponível!',
                                message: participation.CanceledNote,
                                type: EAlertTypes.WARNING,
                                mode: 'alert',
                            });
                            patchState(store, { ParticipantStatusState: { ...store.ParticipantStatusState(), [occuredOn + groupId]: 'error' } });
                            return;
                        }

                        if (participation?.ParticipantStatus === ECardStatus.Confirmed) {
                            alertStore.openAlert({
                                title: 'Check-in indisponível!',
                                message: 'Você já fez o check-in para essa aula.',
                                type: EAlertTypes.WARNING,
                                mode: 'alert',
                            });
                            patchState(store, { ParticipantStatusState: { ...store.ParticipantStatusState(), [occuredOn + groupId]: 'error' } });
                            return;
                        }

                        changeParticipantStatus({
                            group: {
                                GroupId: response.GroupDash.GroupId,
                                OccurredOn: response.GroupDash.OccurredOn,
                                NumberVacanciesAvailable: response.GroupDash.NumberVacanciesAvailable,
                                ParticipantId: participation?.ParticipantId,
                                ParticipantStatus: participation?.ParticipantStatus,
                            },
                            newStatus: ECardStatus.Confirmed
                        });

                    }),
                    catchError((error: HttpErrorResponse) => {
                        errorHandlerService.handleError(error);
                        alertStore.openAlert({
                            title: 'Erro ao carregar a aula',
                            message: 'Ocorreu um erro ao carregar os detalhes da aula. Tente novamente mais tarde.',
                            type: EAlertTypes.DANGER,
                            mode: 'alert',
                        });
                        return of(error);
                    })
                ))
            )),

            isFinished(group: IGroup): boolean {
                if (group) return DateTime.fromISO(group.EndOccurredOn).diffNow().milliseconds < 0;
                return false;
            },

            clearConfigClassesPlanned,

            clearConfigReserveList,

            clearChangeParticipantStatus: () => {
                patchState(store, setPropInit('ChangeParticipantStatus'));
            },

            resetStore(): void {
                patchState(store, initialState, setPropInit('ClassesPlanned', 'ReserveList', 'ChangeParticipantStatus', 'GetActivitySettings'));
                clearConfigClassesPlanned();
                clearConfigReserveList();
            }
        };
    }),
    withReqState('ClassesPlanned', 'ReserveList', 'ChangeParticipantStatus', 'GetActivitySettings'),
);