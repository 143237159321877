import { DEFAULT_CURRENCY_CODE, enableProdMode, importProvidersFrom, inject, LOCALE_ID } from '@angular/core';
import { environment } from '@environments/environment';
import { AppComponent } from './app/app.component';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { IonicRouteStrategy, IonicModule } from '@ionic/angular';
import { provideRouter, RouteReuseStrategy, withComponentInputBinding } from '@angular/router';
import { APP_ROUTES } from './app/app.routes';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TokenInterceptor } from './app/interceptors/token.interceptor';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { Calendar } from '@awesome-cordova-plugins/calendar/ngx';
import ptBr from '@angular/common/locales/pt';
import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { DateTime } from 'luxon';
import { GrowthBook } from "@growthbook/growthbook";

export const growthbook = new GrowthBook({
  apiHost: "https://cdn.growthbook.io",
  clientKey: "sdk-mgDNTjBLc7R3A110",

  trackingCallback: (experiment, result) => {
    console.log("Viewed Experiment", {
      experimentId: experiment.key,
      variationId: result.key
    });
  }
});

growthbook.init({ streaming: true }).then();
window['_growthbook'] = growthbook as GrowthBook;

registerLocaleData(ptBr);
DateTime.local().setLocale("pt-BR");

if (environment.production) {
  enableProdMode();
}

var success = function(message: string) {}
var failure = function(message: string) {}

declare let ClarityPlugin: any;
if (typeof ClarityPlugin !== 'undefined') {
  ClarityPlugin.initialize("opoyabfdnl", success, failure, {isIonic: true});
}

bootstrapApplication(AppComponent, {
  providers: [
    Calendar,
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(BrowserModule, BrowserAnimationsModule, IonicModule.forRoot()),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideRouter(APP_ROUTES, withComponentInputBinding()),
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },   
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
    provideEnvironmentNgxMask(),
    provideAnimations()
  ]
}).catch(err => console.log(err));
