import { patchState, signalStore, withHooks, withMethods, withState } from '@ngrx/signals';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { LocalStorageService } from '@app-services/localStorage.service';
import { inject } from '@angular/core';


interface IHapticsState {
    CanVibrate: boolean;
};

const initialState: IHapticsState = {
    CanVibrate: true,
};

export const HapticsStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods((
    store,
    localStorageService = inject(LocalStorageService),
  ) => {
    return {
        async triggerImpact(style: ImpactStyle): Promise<void> {
            if (!store.CanVibrate()) return;
            try {
              await Haptics.impact({ style });
            } catch (error) {
              console.error('Haptics not available or failed:', error);
            }
        },

        setVibration(CanVibrate: boolean): void {
            localStorageService.setItem('canVibrate', `${CanVibrate}`);
            patchState(store, { CanVibrate });
        },
    };
  }),
  withHooks({
    onInit(store, localStorageService = inject(LocalStorageService)) {
      const CanVibrate = localStorageService.getItem('canVibrate') === 'true';
      patchState(store, { CanVibrate });
    }
  }),
);