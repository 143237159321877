import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { EntityPath, Protocol, url } from '@environments/environment';
import { IActivitySettings, IGroup, IResponseChangeStatus, IRequestChangeStatus, IRequestDeleteParticipant, IGroupFull } from '@app-activities/data-access/entities/activities.interface';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {
  private http = inject(HttpClient);

  public getClassesPlanned(params: { startOn: string; endOn: string; take: number; skip: number; isOrderDescending: boolean; }): Observable<IGroup[]> {
    return this.http.get<IGroup[]>(url(Protocol.https, EntityPath.activity_service, '/Group/GetGroupsDashApp'), {
      params,
      withCredentials: true
    });
  }

  public getParticipationsByPersonId(params: { personId: string; startOn: string; endOn: string; take: number; skip: number; isOrderDescending: boolean }): Observable<IGroup[]> {
    return this.http.get<IGroup[]>(url(Protocol.https, EntityPath.activity_service, '/Group/GetGroupsDashByParticipant'), {
      params,
      withCredentials: true
    });
  }

  public createParticipant(payload: Omit<IRequestChangeStatus, 'ParticipantId'>): Observable<IResponseChangeStatus> {
    return this.http.post<IResponseChangeStatus>(url(Protocol.https, EntityPath.activity_service, '/Participant'), payload, {
      withCredentials: true
    });
  }

  public updateParticipant(payload: IRequestChangeStatus): Observable<IResponseChangeStatus> {
    return this.http.put<IResponseChangeStatus>(url(Protocol.https, EntityPath.activity_service, '/Participant'), payload, {
      withCredentials: true
    });
  }

  public deleteParticipant(params: IRequestDeleteParticipant): Observable<void> {
    return this.http.delete<void>(url(Protocol.https, EntityPath.activity_service, '/Participant'), {
      params: { ...params },
      withCredentials: true
    });
  }

  public getActivitySettings(): Observable<IActivitySettings> {
    return this.http.get<IActivitySettings>(url(Protocol.https, EntityPath.activity_service, '/ActivitySettings/GetActivitySettings'), {
      withCredentials: true
    });
  }

  public getGroupFull(groupId: string, occurredOn: string): Observable<IGroupFull> {
    return this.http.get<IGroupFull>(url(Protocol.https, EntityPath.activity_service, '/Group/GetGroupFull'), {
      params: { groupId, occurredOn },
      withCredentials: true
    });
  }
}

