import { ApplicationRef, ComponentRef, inject, Type, createComponent } from '@angular/core';
import { patchState, signalStore, withHooks, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { tapResponse } from "@ngrx/operators";
import { forkJoin, iif, map, Observable, of, pipe, switchMap, tap } from 'rxjs';
import { DateTime } from 'luxon';
import { AlertStore } from '@shared/components/alert/data-access/alert.store';
import { ErrorHandlerService } from '@app-services/error-handler.service';
import { HttpErrorResponse } from '@angular/common/http';
import { PaymentsService } from '@app-payments/data-access/infra/payments.service';
import { IAccountReceivable, IAuthorization, ICreditCard, IModalErrorPayment, IReceivement, ISubscription, IToken, ISale, IVoucher, ICreateSaleResquest, TAccountsReceivable, IPixCharge } from '@app-payments/data-access/entities/payments.interface';
import { sortByDateAscendingOrder, sortByDateDescendingOrder } from '@shared/utils/sort-by-date.util';
import { EPaymentMethod, ESourceTypeCreateSale } from '@app-payments/data-access/entities/payments.enum';
import { Router } from '@angular/router';
import { EntityPath, Protocol } from "src/environments/environment";
import { braspag, successCodesCheckout } from '@environments/environment.prod';
import * as cryptoJs from 'crypto-js';
import { PaymentErrorComponent } from '@app-payments/feature/payment-error/payment-error.component';
import { PaymentVoucherComponent } from '@app-payments/feature/payment-voucher/payment-voucher.component';
import * as signalR from '@microsoft/signalr';
import { AuthStore } from '@app-auth/data-access/auth.store';
import { setPropError, setPropInit, setPropLoaded, setPropLoading, withReqState } from '@shared/stores/prop-state.store';
import { EAlertTypes } from '@shared/components/alert/data-access/entities/alert.enum';

interface IPaymentsState {
    BillsToPay: {
        bills: IAccountReceivable[];
    };
    PaidBills: {
        bills: IReceivement[];
    };
    Subscriptions: {
        bills: ISubscription[];
    };
    GymHasPix: boolean;
    PaguelaSituation: number | null;
    ScheduledPayments: Record<string, ICreditCard | null>;
    LinkShortened: string | null;
    Token: IToken | null;
    ProviderIdentifier: string | null;
    Voucher: IVoucher | null;
    PaymentError: IModalErrorPayment | null;
    ComponentRef: ComponentRef<any> | null;
    CardData: ICreditCard | null;
    IsTurnedCard: boolean;
    Connection: signalR.HubConnection | null;
    PixCharge: {
        charge: IPixCharge | null;
        isChargeOpen: boolean;
        isChargeProcessed: boolean;
        isChargeWithProblem: boolean;
    },
    CreateSaleStates: Record<string, 'loading' | 'loaded' | 'error'>;
};

const initialState: IPaymentsState = {
    BillsToPay: {
        bills: [],
    },
    GymHasPix: false,
    PaidBills: {
        bills: [],
    },
    Subscriptions: {
        bills: [],
    },
    PaguelaSituation: null,
    ScheduledPayments: {},
    LinkShortened: null,
    Token: null,
    ProviderIdentifier: null,
    Voucher: null,
    PaymentError: null,
    ComponentRef: null,
    CardData: null,
    IsTurnedCard: false,
    Connection: null,
    PixCharge: {
        charge: null,
        isChargeOpen: false,
        isChargeProcessed: false,
        isChargeWithProblem: false
    },
    CreateSaleStates: {},
};

export const PaymentsStore = signalStore(
    { providedIn: 'root' },
    withState(initialState),
    withMethods((
        store,
        paymentsService = inject(PaymentsService),
        authStore = inject(AuthStore),
        router = inject(Router),
        alertStore = inject(AlertStore),
        errorHandlerService = inject(ErrorHandlerService),
        appRef = inject(ApplicationRef)
    ) => {

        const setCyberSource = (id: string): void => {
            if (store.ProviderIdentifier()) return;
            const baseUrl = 'https://h.online-metrix.net/fp/tags.js';
            const providerOrgId = braspag.providerOrgId;
            const providerMerchantId = braspag.providerMerchantId;
 
            patchState(store, { ProviderIdentifier: String(cryptoJs.MD5(id + Date.now())) });
            const scriptSrc = `${baseUrl}?org_id=${providerOrgId}&session_id=${providerMerchantId}${store.ProviderIdentifier()}`;

            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = scriptSrc;

            const noScript = document.createElement('noscript');
            const iframe = document.createElement('iframe');
            iframe.src = scriptSrc;

            document.getElementsByTagName('head')[0].appendChild(script);
            document.body.appendChild(noScript.appendChild(iframe));
        };

        const proccessToken = rxMethod<{ id: string, authorization: IAuthorization, creditCard: Partial<ICreditCard> }>(pipe(
            switchMap(({ id, authorization, creditCard }) => paymentsService.proccessToken(id, authorization).pipe(
                tapResponse({
                    next: (sale) => {
                        const successCode = successCodesCheckout.find((code) => code === sale?.Transacao?.CodigoRetorno);

                        if (successCode) {
                            const now = DateTime.now();
                            patchState(store, {
                                Voucher: {
                                    date: now.toFormat('dd/MM/yyyy'),
                                    time: now.toFormat('hh:mm a'),
                                    value: sale?.Transacao?.Valor ?? null,
                                    brandCardName: creditCard.Bandeira ?? null,
                                    cardNumber: creditCard.Numero ?? null,
                                    proof: sale?.Transacao?.NumeroComprovante ?? null,
                                }
                            });
                            createComponentOnDocument(PaymentVoucherComponent);
                            return;
                        }

                        if (sale?.Transacao && sale.Transacao.MensagemRetornoAdquirencia !== 'Aprovado') {
                            let message = sale.Transacao.MensagemRetornoAdquirencia
                            
                            if (sale?.Transacao?.MensagemRetornoAdquirencia === '' ||
                                sale?.Transacao?.MensagemRetornoAdquirencia === null) {
                                message = 'Não foi possível efetuar a operação'
                            }

                            console.log(message)
                            patchState(store, {
                                PaymentError: {
                                    message,
                                    returnedCode: sale?.Transacao?.CodigoRetorno
                                }
                            });

                            createComponentOnDocument(PaymentErrorComponent);
                        }
                        patchState(store, { ...setPropLoaded('FinishPayment') });
                    },
                    error: (error: HttpErrorResponse) => {
                        errorHandlerService.handleError(error);
                        patchState(store, { ...setPropError('FinishPayment') });
                    }
                })
            ))
        ));

        const createComponentOnDocument = (modal: Type<PaymentErrorComponent | PaymentVoucherComponent>): void => {
            patchState(store, {
                ComponentRef: createComponent(modal as Type<PaymentErrorComponent | PaymentVoucherComponent>, {
                    environmentInjector: appRef.injector,
                })
            });

            document.body.appendChild(store.ComponentRef()!.location.nativeElement);
            appRef.attachView(store.ComponentRef()!.hostView);
        };

        const removeComponent = (): void => {
            store.ComponentRef()?.destroy();
            patchState(store, { ComponentRef: null });
        };

        const openConnection = () => {
            const connection = store.Connection();
            if (connection?.state === signalR.HubConnectionState.Connected) return;

            connection?.on('onPixNotification', data => {
                if (data?.cobrancaPixId === store.PixCharge().charge?.CobrancaPixId && data.title === 'Pix Recebido') {
                    patchState(store, { PixCharge: { ...store.PixCharge(), isChargeOpen: false, isChargeProcessed: true } });
                }
            });

            connection?.start().then().catch((error) => {
                console.error(error);
            });
        };

        const updatePixCharge = (pixCharge: IPixCharge) => {
            patchState(store, {
                PixCharge: {
                    charge: pixCharge,
                    isChargeOpen: pixCharge.Situacao === 'ABERTA',
                    isChargeProcessed: pixCharge.Situacao === 'CONCLUIDA',
                    isChargeWithProblem: ['NAO_REALIZADO', 'DEVOLVIDO', 'REMOVIDA_PELO_USUARIO_RECEBEDOR', 'REMOVIDA_PELO_PSP'].includes(pixCharge.Situacao!)
                }
            });

            if (store.PixCharge().isChargeOpen && !store.PixCharge().isChargeWithProblem) openConnection();
        };

        const createSale = rxMethod<{ AccountsReceivable: IAccountReceivable[], PaymentMethod: EPaymentMethod, Recurring: boolean }>(pipe(
            tap(({ AccountsReceivable }) => {
                const CreateSaleStates = store.CreateSaleStates();
                AccountsReceivable.forEach((bill) => {
                    CreateSaleStates[bill.AccountsReceivableId] = 'loading';
                })
                patchState(store, { CreateSaleStates });
            }),
            switchMap(({ AccountsReceivable, PaymentMethod, Recurring }) => {

                const payload: ICreateSaleResquest = {
                    AccountsReceivable: AccountsReceivable.map((bill) => ({ ...bill, SourceType: ESourceTypeCreateSale[bill.SourceType], CurrentOpenAmount: bill.CurrentOpenAmount, CurrentInterestAmount: bill.CurrentInterestAmount, CurrentPenaltyAmount: bill.AmountFineCurrent, AccountReceivableId: bill.AccountsReceivableId } as TAccountsReceivable)),
                    Installments: 0,
                    Recurring,
                    PersonId: authStore.getAuthData()?.PersonId!,
                    DueDate: DateTime.now().plus({ days: 1 }).toISO()
                }

                const request = PaymentMethod === EPaymentMethod.Pix ? paymentsService.createSalePix(payload) : paymentsService.createSaleCard(payload);
                return request.pipe(
                    tapResponse({
                        next: (response) => {
                            const route = PaymentMethod === EPaymentMethod.Pix ? 'pix' : 'card';
                            const CreateSaleStates = store.CreateSaleStates();
                            AccountsReceivable.forEach((bill) => {
                                CreateSaleStates[bill.AccountsReceivableId] = 'loaded';
                                CreateSaleStates[bill.PersonSubscriptionId] = 'loaded';
                            })
                            router.navigate(['payments', route, response.TokenId]);
                        },
                        error: (error: HttpErrorResponse) => {
                            const CreateSaleStates = store.CreateSaleStates();
                            AccountsReceivable.forEach((bill) => {
                                CreateSaleStates[bill.AccountsReceivableId] = 'error';
                                CreateSaleStates[bill.PersonSubscriptionId] = 'error';
                            });
                            patchState(store, { CreateSaleStates });
                            errorHandlerService.handleError(error);
                        }
                    })
                )
            })
        ));

        const filterBillsByNextDueOn = (bills: IAccountReceivable[]): IAccountReceivable[] => {
            const currentDate = new Date();
        
            const groupedBySubscription = bills.reduce((acc, bill) => {
              if (!acc[bill.PersonSubscriptionId]) {
                acc[bill.PersonSubscriptionId] = [];
              }
              acc[bill.PersonSubscriptionId].push(bill);
              return acc;
            }, {} as Record<string, IAccountReceivable[]>);
        
            return Object.values(groupedBySubscription).map((group) => {
              return group.reduce((closest, current) => {
                const closestDate = new Date(closest.DueOn);
                const currentDateValue = new Date(current.DueOn);
                return Math.abs(currentDateValue.getTime() - currentDate.getTime()) <
                  Math.abs(closestDate.getTime() - currentDate.getTime())
                  ? current
                  : closest;
              });
            });
        }


        return {
            removeComponent,
            checkGymHasPix: rxMethod<void>(pipe(
                tap(() => { patchState(store, { ...setPropLoading('CheckGymHasPix') }) }),
                switchMap(() => {
                    return paymentsService.checkGymHasPix(authStore.getAuthData()?.TenantId as string).pipe(
                        tapResponse({
                            next: (response) => {
                                patchState(store, { GymHasPix: response, ...setPropLoaded('CheckGymHasPix') })
                            },
                            error: (error: HttpErrorResponse) => {
                                errorHandlerService.handleError(error)
                                patchState(store, { ...setPropError('CheckGymHasPix') });
                            }
                        })
                    )
                }),
            )),

            getBillsToPay: rxMethod<void>(pipe(
                tap(() => { patchState(store, { ...setPropLoading('BillsToPay') }) }),
                switchMap(() => {
                    const personId = authStore.getAuthData()?.PersonId as string;
                    return paymentsService.getBillsToPay(personId).pipe(
                        tapResponse({
                            next: (response) => {
                                const bills = sortByDateAscendingOrder(response, ['DueDate'])
                                const filteredBills = filterBillsByNextDueOn(bills);

                                patchState(store, {
                                    BillsToPay: {
                                        bills: filteredBills,
                                    },
                                    ...setPropLoaded('BillsToPay')
                                });                            },
                            error: (error: HttpErrorResponse) => {
                                errorHandlerService.handleError(error);
                                patchState(store, { ...setPropError('BillsToPay') });
                            }
                        })
                    )
                })
            )),

            getPaidBills: rxMethod<void>(pipe(
                tap(() => { patchState(store, { ...setPropLoading('PaidBills') }) }),
                switchMap(() => {
                    const personId = authStore.getAuthData()?.PersonId as string;
                    return paymentsService.getPaidBills(personId).pipe(
                        tapResponse({
                            next: (response) => {
                                const paidBills = sortByDateDescendingOrder(response, ['DueDate'])

                                patchState(store, {
                                    PaidBills: {
                                        bills: paidBills,
                                    },
                                    ...setPropLoaded('PaidBills')
                                });                            },
                            error: (error: HttpErrorResponse) => {
                                errorHandlerService.handleError(error);
                                patchState(store, { ...setPropError('PaidBills') });
                            }
                        })
                    )
                })
            )),

            getSubscriptions: rxMethod<void>(pipe(
                tap(() => { patchState(store, { ...setPropLoading('Subscriptions') }) }),
                switchMap(() => {
                    const personId = authStore.getAuthData()?.PersonId as string;
                    return paymentsService.getSubscriptions(personId).pipe(
                        tapResponse({
                            next: (response) => {
                                const subscriptions = sortByDateDescendingOrder(response, ['DataCadastro'])

                                patchState(store, {
                                    Subscriptions: {
                                        bills: subscriptions,
                                    },
                                    ...setPropLoaded('Subscriptions')
                                });                            },
                            error: (error: HttpErrorResponse) => {
                                errorHandlerService.handleError(error);
                                patchState(store, { ...setPropError('Subscriptions') });
                            }
                        })
                    )
                })
            )),


            clearBills: (attr: 'BillsToPay' | 'PaidBills' | 'Subscriptions') => {
                patchState(store, { [attr]: { bills: [] }, ...setPropInit(attr) });
            },

            clearConfigBills: (attr: 'BillsToPay' | 'PaidBills' | 'Subscriptions') => {
                patchState(store, { [attr]: { bills: store[attr]().bills }, ...setPropInit(attr) });
            },

            getPaguelaSituation: rxMethod<void>(pipe(
                tap(() => { patchState(store, { ...setPropLoading('GetPaguelaSituation') }) }),
                switchMap(() => paymentsService.getAccountIdPaguelaByPersonId(authStore.getAuthData()?.TenantId as string).pipe(
                    switchMap((accountId) => {
                        if(!accountId) {
                            patchState(store, { PaguelaSituation: null, ...setPropLoaded('GetPaguelaSituation') })
                            return of(null);
                        }
                        return paymentsService.getPaguelaSituation(accountId).pipe(
                            tapResponse({
                                next: (response) => {
                                    patchState(store, { PaguelaSituation: response.Situacao, ...setPropLoaded('GetPaguelaSituation') })
                                },
                                error: (error: HttpErrorResponse) => {
                                    errorHandlerService.handleError(error)
                                    patchState(store, { ...setPropError('GetPaguelaSituation') });
                                }
                            })
                        )
                    }),
                )),
            )),

            createSale,

            shortenLink: rxMethod<{ tokenId: string, isPix: boolean }>(pipe(
                switchMap(({ tokenId, isPix }) => {
                    return paymentsService.shortenLink(`https://app.pague.la/#/checkout${isPix ? '/pix' : ''}?tokenId=${tokenId}`).pipe(
                        tapResponse({
                            next: (code) => {
                                patchState(store, { LinkShortened: `${Protocol.https}${EntityPath.shortener}/${code}` })
                            },
                            error: (error: HttpErrorResponse) => {
                                errorHandlerService.handleError(error)
                            }
                        })
                    )
                })
            )),

            getToken: rxMethod<string>(pipe(
                switchMap((tokenId) => {
                    return paymentsService.getToken(tokenId).pipe(
                        tapResponse({
                            next: (response) => {
                                patchState(store, { Token: response, Connection: paymentsService.getConnection(response.ContaId!) });
                            },
                            error: (error: HttpErrorResponse) => {
                                errorHandlerService.handleError(error)
                            }
                        })
                    )
                })
            )),

            deleteCard: rxMethod<string>(pipe(
                tap(() => { patchState(store, { ...setPropLoading('DeleteCard') }) }),
                switchMap((cardId) => {
                    return paymentsService.deleteCard(cardId).pipe(
                        tapResponse({
                            next: () => {
                                alertStore.openAlert({
                                    title: 'Cartão excluído!',
                                    message: 'O cartão foi excluído com sucesso.',
                                    type: EAlertTypes.SUCCESS
                                });
                                patchState(store, { ...setPropLoaded('DeleteCard') })
                            },
                            error: (error: HttpErrorResponse) => {
                                errorHandlerService.handleError(error);
                                patchState(store, { ...setPropError('DeleteCard') });
                            }
                        })
                    )
                })
            )),

            finishPayment: rxMethod<{ creditCard: Partial<ICreditCard>, sale: ISale }>(pipe(
                tap(() => { patchState(store, { ...setPropLoading('FinishPayment') }) }),
                switchMap(({ creditCard, sale }) => {
                    setCyberSource(sale?.TokenId);

                    return forkJoin({
                        ip: paymentsService.getIp().pipe(map((data) => data.ip)),
                        account: paymentsService.getPaguelaAccount(sale?.Cliente?.ContaId ?? ''),
                    }).pipe(
                        tapResponse({
                            next: ({ ip, account }) => {
                                const authorization: IAuthorization = {
                                    CaixaPreta: store.ProviderIdentifier()!,
                                    Ipv4: account?.TipoCadastro === 'PagueLa' ? ip : '',
                                    Valor: null,
                                    PedidoLojaId: null,
                                    NumeroParcelas: sale.Parcelas ?? 1,
                                    TipoMeioPagamento: 'CreditCard',
                                    MeioPagamento: '',
                                    CapturarAutomaticamente: true,
                                    CartaoId: (creditCard as ICreditCard)?.CartaoId ?? null,
                                    NumeroCartao: creditCard?.Numero ?? null,
                                    ContaId: sale?.Cliente?.ContaId ?? null,
                                    NomeComprador: creditCard?.NomeComprador ?? null,
                                    CodigoSegurancaCartao: creditCard?.CodigoSeguranca ?? null,
                                    NomeCompradorImpressoCartao: creditCard?.NomeComprador ?? null,
                                    BandeiraCartao: creditCard?.Bandeira ?? null,
                                    DataValidadeCartao: DateTime.fromFormat(creditCard.DataValidade ?? '', 'MM/yy').toFormat('MM/yyyy'),
                                }
                                proccessToken({ id: sale.TokenId, authorization, creditCard });
                            },
                            error: (error: HttpErrorResponse) => {
                                errorHandlerService.handleError(error);
                                patchState(store, { ...setPropError('FinishPayment') });
                            }
                        })

                    )

                })
            )),

            clearVoucherAndErrorPayment: () => {
                patchState(store, { Voucher: null, PaymentError: null });
            },

            setIsTurned: (isTurned: boolean) => {
                patchState(store, { IsTurnedCard: isTurned });
            },

            setCardData: (data: ICreditCard) => {
                patchState(store, { CardData: data });
            },

            updatePixCharge,

            removeCyberSource: () => {
                const scripts = document.getElementsByClassName('script-cybersource') as HTMLCollectionOf<HTMLElement>;
                const iframes = document.querySelectorAll('iframe') as NodeListOf<HTMLIFrameElement>;
                const tagsIframe = document.querySelector('#tmx_tags_iframe') as HTMLIFrameElement;

                if (!scripts?.length && !iframes?.length && !tagsIframe) return;

                if (scripts[0]) { scripts[0].remove(); }
                if (iframes[0]) { iframes[0].remove(); }
                if (tagsIframe) { tagsIframe.remove(); }
            },

            getPagueLaSale: rxMethod<{ sourceId: string; salePagueLaId: string }>(pipe(
                switchMap(({ salePagueLaId, sourceId }) => {
                    if (!salePagueLaId) {
                        patchState(store, { ScheduledPayments: { ...store.ScheduledPayments(), [sourceId]: null } });
                        return of(null);
                    }
                    return paymentsService.getPagueLaSale(salePagueLaId).pipe(
                        tapResponse({
                            next: (response) => {
                                patchState(store, { ScheduledPayments: { ...store.ScheduledPayments(), [sourceId]: response.Cartao } })
                            },
                            error: (error: HttpErrorResponse) => {
                                errorHandlerService.handleError(error)
                            }
                        })
                    )
                })
            )),

            addCardForSubscription: rxMethod<string>(pipe(
                tap((sourceId) => { patchState(store, { CreateSaleStates: { ...store.CreateSaleStates(), [sourceId]: 'loading' } }) }),
                switchMap((sourceId) => {
                    return paymentsService.getAccountsReceivablesByPersonSubscriptionId(sourceId).pipe(
                        tapResponse({
                            next: (response) => {
                                if (response.length) createSale({ AccountsReceivable: response, PaymentMethod: EPaymentMethod.CARD, Recurring: true });
                                else {
                                    patchState(store, { CreateSaleStates: { ...store.CreateSaleStates(), [sourceId]: 'error' } });
                                    alertStore.openAlert({
                                        title: 'Erro ao adicionar cartão',
                                        message: 'Não foi possível adicionar o cartão, pois não há cobranças pendentes.',
                                        type: EAlertTypes.WARNING,
                                        mode: 'alert'
                                    });
                                }
                            },
                            error: (error: HttpErrorResponse) => {
                                errorHandlerService.handleError(error);
                                patchState(store, { CreateSaleStates: { ...store.CreateSaleStates(), [sourceId]: 'error' } });
                            }
                        })
                    )
                })
            )),

            resetStore(): void {
                patchState(store, initialState, setPropInit('BillsToPay', 'PaidBills', 'Subscriptions', 'DeleteCard', 'FinishPayment', 'CheckGymHasPix', 'GetPaguelaSituation'));
            }
        };
    }),
    withReqState('BillsToPay', 'PaidBills', 'Subscriptions', 'DeleteCard', 'FinishPayment', 'CheckGymHasPix', 'GetPaguelaSituation'),
    withHooks({
        onDestroy(store) {
            store.Connection()?.stop();
        },
    })
);